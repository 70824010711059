<template>
  <div id="leaseOnlyDialog">
    <el-dialog
      title="临时车可进时间"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <ul class="allowTemp">
        <li v-for="(item, index) of allowTemp" :key="index">
          <el-time-select
            v-model="allowTemp[index].start"
            :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }"
            placeholder="开始时间"
            :editable="false"
            :clearable="false"
          />
          <span style="margin: 0 5px">--</span>
          <el-time-select
            v-model="allowTemp[index].end"
            :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }"
            placeholder="结束时间"
            :editable="false"
            :clearable="false"
          />
          <span class="delete-item" @click="deleteItem(index)">删除</span>
        </li>
        <el-button type="primary" plain class="create-item" @click="createItem"
          >新建时间段</el-button
        >
      </ul>

      <el-button type="primary" @click="confirm" class="confirm"
        >确 定 保 存</el-button
      >
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getInfo"],
  data() {
    return {
      show: false,
      loading: false,
      allowTemp: [
        { start: "22:30", end: "01:30" },
        { start: "01:00", end: "18:30" },
      ],
    };
  },

  methods: {
    deleteItem(index) {
      this.allowTemp.splice(index, 1);
    },
    createItem() {
      const lastAllowTemp = this.allowTemp.slice(-1)[0];
      if (lastAllowTemp && (!lastAllowTemp.start || !lastAllowTemp.end)) {
        this.$message.warning("请补全为空的时间段");
        return;
      }
      this.allowTemp.push({ start: "", end: "" });
    },
    async confirm() {
      for (let i = 0, len = this.allowTemp.length; i < len; i++) {
        if (!this.allowTemp[i].start || !this.allowTemp[i].end) {
          this.$message.warning("请补全之前的时间段");
          return;
        }
      }

      try {
        this.loading = true;
        let res = await this.$http.post("/parking/edit", {
          allowTemp: this.allowTemp,
          parkingLotId: this.$route.params.id,
        });
        if (res.code === 0) {
          this.$message.success("编辑成功!");
          this.$emit("success");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#leaseOnlyDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 450px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .leaseOnly {
          margin-bottom: 20px;
        }
        .allowTemp {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            &:last-child {
              margin-bottom: 0;
            }
            .el-date-editor {
              flex: 1;
            }
            .delete-item {
              width: 40px;
              text-align: right;
              cursor: pointer;
              color: $main-color;
            }
          }
          .create-item {
            width: 100%;
          }
        }
      }
      .confirm {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
</style>
